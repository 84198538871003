/**
 * CardResource
 */

import React from 'react';
import classNames from 'classnames';
import {kebabCaseToPascalCase} from 'utils';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardResource.module.scss';

const CardResource = ({
    href,
    image,
    label,
    title,
    text,
    category,
    iconName,
    icon,
    textColor,
    backgroundColor,
    backgroundColorHex,
    imageAsBackground,
    hidePreamble,
    onClick,
}) => {
    const hasImage = Boolean(image && image.src);

    const hasTextColor = !iconName && !backgroundColor && textColor;
    const bgIsLight = backgroundColor && backgroundColor === 'yellow';
    const bgIsDark = (backgroundColor && backgroundColor !== 'yellow') || iconName;
    const isBlackTextColor = (hasTextColor && textColor === 'black') || bgIsLight;
    const isWhiteTextColor = (hasTextColor && textColor === 'white') || bgIsDark;

    const style = {};
    if(backgroundColorHex && !iconName && !backgroundColor) {
        style.backgroundColor = backgroundColorHex;
    }

    const iconModifier = kebabCaseToPascalCase(iconName);
    const bgModifier = kebabCaseToPascalCase(backgroundColor);

    const classes = classNames(
        s['CardResource'],
        {[s['CardResource--NoImage']]: !hasImage},
        {[s['CardResource--ImageAsBackground']]: !iconName && imageAsBackground},
        {[s[`CardResource--${iconModifier}`]]: iconName && !icon },
        {[s['CardResource--Agenda2030']]: iconName && !icon },
        {[s[`CardResource--Background${bgModifier}`]]: !iconName && backgroundColor },
        {[s['CardResource--BgHex']]: !iconName && !backgroundColor && backgroundColorHex },
        {[s['CardResource--Icon']]: icon || iconName},
        {[s['CardResource--WhiteText']]: isWhiteTextColor},
        {[s['CardResource--BlackText']]: isBlackTextColor},
    );

    const iconClasses = classNames(
        s['CardResource__Icon'],
        {[s[`CardResource__Icon--${iconModifier}`]]: iconName && !icon },
    );

    const categories = category.slice(0, 3);
    const shortenedText = text.length > 150 ? `${text.slice(0, 150)}...` : text;

    // TODO: Check fa-icons below
    return (
        <a
            href={href}
            className={classes}
            aria-label={title}
            onClick={(e) => onClick(e)}
            style={style}
        >
            {hasImage && (
                <BackgroundImage
                    image={image}
                    className={s['CardResource__Image']}
                    key={image.id}
                />
            )}
            {(icon || iconName) &&
                <div className={iconClasses}>
                    {icon &&
                        <img src={icon.src} alt={icon.alt} width={icon.with} height={icon.height} title={icon.title} />
                    }
                </div>
            }
            <article className={s['CardResource__Content']}>
                {label &&
                    <div className={s['CardResource__Label']}>{label}</div>
                }
                <h2 className={s['CardResource__Title']}>{title}</h2>
                {!hidePreamble && (<p className={s['CardResource__Text']}>{shortenedText}</p>)}
                {categories && categories.length > 0 && (
                    <div className={s['CardResource__Categories']}>
                        {categories.map((c, index) => (
                            <p key={index} className={s['CardResource__Category']}>
                                <i
                                    className={classNames(
                                        s['CardResource__Fas'],
                                        {[c.fontawesome]: c.fontawesome},
                                        {'fas fa-tag': !c.fontawesome},
                                    )}
                                />
                                {c.name}
                            </p>
                        ))}

                        {category.length > 3 && (
                            <p className={classNames(s['CardResource__Category'], s['CardResource__Category--More'])}>
                                + Fler
                            </p>
                        )}
                    </div>
                )}
            </article>
        </a>
    );
};

CardResource.defaultProps = {
    category: [],
    text: '',
    imageAsBackground: false,
    onClick: () => {},
};

export default CardResource;
