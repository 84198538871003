/**
 * CardResourceList
 */

import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { objToQuery } from 'utils/Helpers';
import { httpGet } from 'utils/Http';
import { dataLayerPush } from 'utils/Datalayer';
import { snakeCaseToCamelCase } from 'utils';
import FilterSelect from 'Components/FilterSelect';
import ButtonReadMore from 'Components/ButtonReadMore';
import CardResource from 'Components/CardResource';
import s from './CardResourceList.module.scss';

const CardResourceList = ({
    identifier,
    tag,
    title,
    categories,
    subjects,
    showFilter,
    showDropdownFilterField,
    showSubjectsFilter,
    searchPlaceholder,
    hidePreambles,
    extendable,
    rootPage,
    currentPage,
    listPage,
    items,
    initialFetch,
    baseUrl,
    categoryFilter,
    preselectedCategory,
    preselectedSubjectCategories,
    preselectedPlaces,
    preselectedBusinessConcepts,
    preselectedBusinessPartners,
    initialNumberOfCards,
    listFilter,
    onShowComponent,
    onHideComponent,
}) => {
    const [mounted, setMounted] = useState(false);
    const [query, setQuery] = useState('');
    const [fetching, setFetching] = useState(false);
    const [currItems, setCurrItems] = useState(initialFetch ? [] : items);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [showAllButton, setShowAllButton] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(categoryFilter);
    const [selectedSubjects, setSelectedSubjects] = useState(preselectedSubjectCategories);
    const [currentNumberOfCards, setCurrentNumberOfCards] = useState(initialNumberOfCards);

    const selectedPlace = preselectedPlaces;
    const businessConcepts = preselectedBusinessConcepts;
    const businessPartners = preselectedBusinessPartners;

    const fetchResources = (isInitialSearch) => {
        setFetching(true);

        const defaultCat = selectedCategory && selectedCategory.length > 0 ? selectedCategory : preselectedCategory;
        const cat = defaultCat && defaultCat.length > 0 ? defaultCat : '';
        const subCat = selectedSubjects && selectedSubjects.length > 0 ? selectedSubjects : '';

        const queries = objToQuery({
            category: cat,
            subjectCategories: subCat,
            q: query || '',
            rootPage: listPage && listPage.id ? listPage.id : (rootPage || ''),
            exclude: currentPage || '',
            place: selectedPlace ? selectedPlace.join(',') : '',
            listFilter: listFilter || 'or',
            businessPartners: businessPartners ? businessPartners.join(',') : '',
            businessConcepts: businessConcepts ? businessConcepts.join(',') : '',
        });
        const url = `${baseUrl}/wt/api/v2/resource/${queries}`;
        httpGet(url).then((result) => {
            setFetching(false);

            const formattedItems = snakeCaseToCamelCase(result.items);
            setCurrItems(formattedItems);

            if (isInitialSearch && result && result.items && result.items.length) {
                onShowComponent();
            }
            else if (isInitialSearch) {
                onHideComponent();
            }
            if (!isInitialSearch) {
                dataLayerPush({
                    'event': 'search',
                    'searchType': 'resource',
                    'searchInput': query,
                    'hits': result && result.items ? result.items.length : 0,
                    'citySearch': selectedPlace ? selectedPlace.join(',') : '',
                    'searchCategory': selectedCategory ? selectedCategory.join(',') : '',
                });
            }
        }).catch(() => {
            setFetching(false);
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && initialFetch) {
            fetchResources(true);
        }
        setMounted(true);
    }, []);

    useEffect(() => {
        if(mounted) {
            fetchResources(false);
        }
    }, [selectedCategory, selectedSubjects]);

    useEffect(() => {
        if(mounted) {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
            setTypingTimeout(setTimeout(() => {
                fetchResources(false);
            }, 200));
        }
    }, [query]);

    useEffect(() => {
        if(currentNumberOfCards === initialNumberOfCards * 4) {
            setShowAllButton(true);
        }
    }, [currentNumberOfCards]);

    const handleSearchFieldOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchResources(false);
        }
    };

    const handleCategoryOnChange = (e) => {
        const value = e.value === 0 ? '' : e.value;
        setSelectedCategory([value]);
    };

    const handleSubjectOnChange = (e) => {
        const value = e.value === 0 ? '' : e.value;
        setSelectedSubjects([value]);
    };

    const handleSearchFieldOnChange = (e) => {
        const value = e.currentTarget.value;
        setQuery(value);
    };

    const handleClearOnClick = () => {
        setQuery('');
    };

    const handleResultItemClick = () => {
        if (query !== '' ||  (selectedPlace && selectedPlace.length)) {
            dataLayerPush({
                'event': 'searchClick',
                'searchType': 'search resource',
                'searchInput': query,
                'citySearch': selectedPlace ? selectedPlace.join(',') : '',
                'searchCategory': undefined,
            });
        }
    };

    const handleListExtension = () => {
        setCurrentNumberOfCards(currentNumberOfCards + initialNumberOfCards);
    };

    const classes = classNames(
        s['CardResourceList'],
        {[s['CardResourceList--Filter']]: showFilter},
    );

    const hasListPage = listPage && listPage.link && listPage.link.href;
    const showShowMoreButton = !fetching && currItems && currItems.length > currentNumberOfCards;

    const currentCards = [...currItems].slice(0, currentNumberOfCards);

    return (
        <section className={classes}>
            <div className={s['CardResourceList__Header']}>
                {tag && (
                    <span className={s['CardResourceList__Tag']}>{tag}</span>
                )}
                {title && (
                    <h2 className={s['CardResourceList__Title']}>{title}</h2>
                )}

                {showFilter && (
                    <div className={s['CardResourceList__Filter']}>
                        <div className={s['CardResourceList__Search']}>
                            <div className={s['CardResourceList__SearchFieldWrapper']}>
                                <input
                                    value={query}
                                    name="hero-search"
                                    autoComplete="off"
                                    placeholder={searchPlaceholder ? searchPlaceholder : 'Sök bland resurser'}
                                    className={s['CardResourceList__SearchField']}
                                    onChange={(e) => handleSearchFieldOnChange(e)}
                                    onKeyDown={(e) => handleSearchFieldOnKeyDown(e)}
                                    type="text"
                                />
                            </div>

                            {query && (
                                <button
                                    type="button"
                                    onClick={(e) => handleClearOnClick(e)}
                                    className={s['CardResourceList__Clear']}
                                />
                            )}

                            <button
                                type="submit"
                                onClick={() => fetchResources(false)}
                                className={classNames(s['CardResourceList__Submit'], s['CardResourceList__Submit--Active'])}
                                aria-label="Sök resurser"
                            />
                        </div>
                        {categories && showDropdownFilterField && !categoryFilter.length && (
                            <div className={s['CardResourceList__Categories']}>
                                <FilterSelect
                                    id={`${identifier}-card-resource-list-category`}
                                    value={selectedCategory}
                                    placeholder="Välj kategori"
                                    onChange={(e) => handleCategoryOnChange(e)}
                                    options={categories.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                    }))}
                                    icon="Category"
                                    isFullWidth={true}
                                    modifier="IncreaseHeight"
                                />
                            </div>
                        )}
                        {subjects && showSubjectsFilter && (
                            <div className={s['CardResourceList__Categories']}>
                                <FilterSelect
                                    id={`${identifier}-card-resource-list-subject`}
                                    value={selectedSubjects}
                                    placeholder="Välj ämne"
                                    onChange={(e) => handleSubjectOnChange(e)}
                                    options={subjects.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                    }))}
                                    icon="Category"
                                    isFullWidth={true}
                                    modifier="IncreaseHeight"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

            {currentCards && (
                <div className={s['CardResourceList__Resources']}>
                    {currentCards.map((item, index) => (
                        <div
                            className={s['CardResourceList__Resource']}
                            key={index}
                        >
                            <CardResource
                                hidePreamble={hidePreambles}
                                onClick={(e) => handleResultItemClick(e)}
                                {...item}
                            />
                        </div>
                    ))}
                </div>
            )}

            {!fetching && (!currItems || !currItems.length) && (
                <h3 className={s['CardResourceList__NoHits']}>
                    Tyvärr, hittar inget som matchar din sökning
                </h3>
            )}

            {!showAllButton && showShowMoreButton && extendable && (
                <div className={s['CardResourceList__ButtonWrapper']}>
                    <ButtonReadMore
                        text="Visa fler"
                        onClick={(e) => handleListExtension(e)}
                    />
                </div>
            )}

            {((!extendable && hasListPage) || (showAllButton && hasListPage)) && (
                <div className={s['CardResourceList__ButtonWrapper']}>
                    <a
                        href={listPage.link.href}
                        className={s['CardResourceList__GoToPage']}
                    >Visa alla</a>
                </div>
            )}
        </section>
    );
};

CardResourceList.defaultProps = {
    identifier: '',
    baseUrl: '',
    items: [],
    initialFetch: true,
    categoryFilter: [],
    preselectedCategory: [],
    preselectedSubjectCategories: [],
    showSubjectsFilter: false,
    onHideComponent: () => {},
    onShowComponent: () => {},
};

export default CardResourceList;
